<template>
	<div class="page">
		<gc-header headerData="firamwareTaskDetail"></gc-header>
		<div class="page-main directive-detail-main">
			<gc-group-detail :data="basicInfoData"></gc-group-detail>
			<div class="directive-detail-table-show">
				<div class="table-header">设备列表</div>
				<div class="table-box">
					<div class="serach">
						<gc-custom-search
							keyWord="设备编号"
							width="280px"
							:search.sync="form.deviceNo"
						></gc-custom-search>
						<gc-custom-search
							keyWord="前置版本号"
							width="280px"
							:search.sync="form.preVersion"
						></gc-custom-search>
						<gc-custom-search
							keyWord="目标版本号"
							width="280px"
							:search.sync="form.targetVersion"
						></gc-custom-search>
						<gc-custom-search
							keyWord="固件下载状态"
							width="280px"
							type="select"
							:search.sync="form.downloadStatus"
							:search-option="downloadStatusOptions"
							needAllForSearch
						></gc-custom-search>
						<gc-custom-search
							keyWord="验证状态"
							width="280px"
							type="select"
							:search.sync="form.verifyStatus"
							:search-option="vertifyStatusOptions"
							needAllForSearch
						></gc-custom-search>
						<gc-custom-search
							keyWord="任务状态"
							width="280px"
							type="select"
							:search.sync="form.taskStatus"
							:search-option="subTaskStatusOptions"
							needAllForSearch
						></gc-custom-search>
						<div class="serach-ops">
							<el-button
								type="primary"
								@click="
									pagination.currentPage = 1
									findTableList()
								"
								v-click-blur
							>
								查询
							</el-button>
							<el-button @click="handleReset" v-click-blur>重置</el-button>
						</div>
						<div class="export-ops">
							<el-button @click="handleExportDataList" type="primary" v-click-blur>导出</el-button>
						</div>
					</div>
					<gc-table
						v-loading="loading"
						border
						showPage
						:columns="columns"
						:table-data="tableData"
						:current-page.sync="pagination.currentPage"
						:total="pagination.total"
						:pageSize="pagination.pageSize"
						gloabSortDisable
						paginationLayout="prev, next, jumper"
						@current-page-change="currentPageChange"
					></gc-table>
				</div>
			</div>
		</div>
		<CancelTaskModal v-if="cancelTaskVisible" :otaTaskDetailId="otaTaskDetailId" @close-modal="handleCloseModal" />
	</div>
</template>

<script>
import {
	firmwareTypeMap,
	subTaskStatusMap,
	downloadStatusMap,
	vertifyStatusMap,
	subTaskStatusOptions,
	vertifyStatusOptions,
	downloadStatusOptions,
} from '@/config/const'
import { apiGetFirmwareTaskDetails, apiGetSubTaskDetails, apiDownloadSubTaskExcel } from '@/apis/firmware.api'
import CancelTaskModal from './components/CancelTaskModal.vue'

export default {
	name: 'firamwareTaskDetail',
	components: { CancelTaskModal },
	data() {
		return {
			subTaskStatusOptions,
			vertifyStatusOptions,
			downloadStatusOptions,
			taskId: this.$route.query.taskId,
			form: {
				deviceNo: '',
				preVersion: '',
				targetVersion: '',
				downloadStatus: '',
				verifyStatus: '',
				taskStatus: '',
			},
			columns: [
				{
					key: 'deviceNo',
					name: '设备编号',
					width: 130,
					tooltip: true,
				},
				{
					key: 'firmwareType',
					name: '固件类型',
					render: (h, row) => {
						return h('span', null, firmwareTypeMap[row.firmwareType])
					},
				},
				{
					key: 'preVersion',
					name: '前置版本号',
				},
				{
					key: 'targetVersion',
					name: '目标版本号',
				},
				{
					key: 'downloadStatus',
					name: '固件下载状态',
					render: (h, row) => {
						return h('span', null, downloadStatusMap[row.downloadStatus])
					},
				},
				{
					key: 'verifyStatus',
					name: '验证状态',
					render: (h, row) => {
						return h('span', null, vertifyStatusMap[row.verifyStatus])
					},
				},
				{
					key: 'taskStatus',
					name: '任务状态',
					render: (h, row) => {
						return h('span', null, subTaskStatusMap[row.taskStatus])
					},
				},
				{
					key: 'execDownloadCount',
					name: '下载次数',
				},
				{
					key: 'reason',
					name: '失败原因',
					tooltip: true,
				},
				{
					key: 'createStaffName',
					name: '创建人',
				},
				{
					key: 'createTime',
					name: '创建时间',
					width: 180,
				},
				{
					key: 'oprate',
					name: '操作',
					sortDisable: true,
					width: 130,
					fixed: 'right',
					render: (h, row) => {
						return h('div', {}, [
							row.taskStatus === '1'
								? h(
										'el-button',
										{
											style: { color: '#e5662e' },
											props: {
												type: 'text',
												size: 'medium',
											},
											on: {
												click: () => {
													this.otaTaskDetailId = row.otaTaskDetailId
													this.cancelTaskVisible = true
												},
											},
										},
										'取消',
								  )
								: '-',
						])
					},
				},
			],
			loading: false,
			pagination: {
				currentPage: 1,
				pageSize: 10,
				total: 0,
			},
			tableData: [],
			taskDetails: {},
			otaTaskDetailId: null,
			cancelTaskVisible: false,
		}
	},
	computed: {
		basicInfoData() {
			const d = this.taskDetails
			return {
				title: '任务属性',
				row: 4,
				list: [
					{
						key: 'taskName',
						label: '批次任务名称',
						value: d.taskName,
					},
					{
						key: 'otaDeviceTypeName',
						label: 'OTA设备类型',
						value: d.otaDeviceTypeName,
					},
					{
						key: 'firmwareType',
						label: '固件类型',
						value: firmwareTypeMap[d.firmwareType],
					},
					{
						key: 'statusName',
						label: '任务状态',
						value: d.statusName,
					},
					{
						key: 'taskEndTime',
						label: '截止时间',
						value: d.taskEndTime,
					},
					{
						key: 'downloadLimitCount',
						label: '下载次数限制',
						value: d.downloadLimitCount,
					},
					{
						key: 'operationName',
						label: '操作人',
						value: d.operationName,
					},
					{
						key: 'createStaffName',
						label: '创建人',
						value: d.createStaffName,
					},
					{
						key: 'createTime',
						label: '创建时间',
						value: d.createTime,
					},
				],
			}
		},
	},

	created() {
		this.queryFirmwareTaskDetails()
		this.findTableList()
	},

	methods: {
		/**
		 * 查询固件任务详情
		 * @param {*} taskId
		 */
		async queryFirmwareTaskDetails() {
			const data = await apiGetFirmwareTaskDetails(this.taskId)
			this.taskDetails = data
		},

		async findTableList() {
			this.loading = true
			const searchParams = {
				...this.form,
				taskId: this.taskId,
				current: this.pagination.currentPage,
				size: this.pagination.pageSize,
			}
			try {
				const { records = [], total } = await apiGetSubTaskDetails(searchParams)
				this.tableData = records
				this.pagination.total = total
			} finally {
				this.loading = false
			}
		},

		async handleExportDataList() {
			if (!this.tableData.length) {
				return this.$message.warning('暂无表格数据')
			}
			const searchParams = {
				...this.form,
				taskId: this.taskId,
			}
			const data = await apiDownloadSubTaskExcel(searchParams)
			const blob = new Blob([data], { type: 'application/vnd.ms-excel' })
			const link = document.createElement('a')
			link.style.display = 'none'
			link.href = URL.createObjectURL(blob)
			link.download = '设备列表明细'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		},

		currentPageChange(current) {
			this.pagination.currentPage = current
			this.findTableList()
		},

		handleReset() {
			for (const key in this.form) {
				this.form[key] = null
			}
			this.pagination.currentPage = 1
			this.findTableList()
		},

		handleCloseModal(refresh) {
			this.cancelTaskVisible = false
			if (refresh) {
				this.findTableList()
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import '../../run-manage/runLayout.scss';
.directive-detail-main {
	flex-direction: column;
	padding: 0 20px;
	.directive-detail-table-show {
		flex: 1;
		display: flex;
		flex-direction: column;
		height: 0;
		.table-header {
			font-size: 17px;
			font-weight: 600;
			color: #333333;
			padding-bottom: 20px;
		}
		.table-box {
			flex: 1;
			height: 0;
			display: flex;
			flex-direction: column;
			.serach {
				display: flex;
				flex-wrap: wrap;
				.serach-ops {
					::v-deep .el-button {
						border-radius: 8px;
					}
				}
				& > div {
					margin-right: 12px;
					margin-bottom: 8px;
				}
				.export-ops {
					margin-left: auto;
					margin-right: 0;
				}
			}
			::v-deep .gc-table {
				flex: 1;
				height: 0;
				margin-top: 8px;
			}
		}
	}
}
::v-deep .desc {
	.el-textarea.is-disabled .el-textarea__inner {
		border: 1px solid #d9d9d9;
		background: #ffffff !important;
		color: #4e4e4e;
	}
}
</style>
